import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
@Injectable()
export class AppResolver  {
  constructor(private router: Router) {}

  resolve(): Observable<boolean> {
    return of(true);
  }
}
