import { NgModule } from '@angular/core';
import { CalendarioService } from './calendario.service';
import { ChunkDownloadService } from './chunk-download.service';
import { ContactsSharedServices } from './contacts.services';
import { DashboardSharedService } from './dashboard.service';
import { DomainValuesSharedService } from './domain-values.shared.service';
import { EventService } from './event.service';
import { FilesService } from './files.service';
import { ProjectPurchaseService } from './project-purchase.service';
import { ProjectSaleService } from './project.sale.service';
import { ProjectService } from './project.service';
import { ProjectsEventsSharedService } from './projects-events.service';
import { PropertyService } from './property.service';
import { RealitycsService } from './realitycs.service';
import { ServerEventService } from './server-event.service';
import { SourcesSharedService } from './sources-shared.service';
import { UsersService } from './users.service';
import { UtilsService } from './utils.services';

const PROVIDERS = [
  CalendarioService,
  ChunkDownloadService,
  ContactsSharedServices,
  DashboardSharedService,
  DomainValuesSharedService,
  EventService,
  FilesService,
  ProjectPurchaseService,
  ProjectSaleService,
  ProjectService,
  ProjectsEventsSharedService,
  PropertyService,
  RealitycsService,
  ServerEventService,
  SourcesSharedService,
  UsersService,
  UtilsService,
];

@NgModule({
  providers: PROVIDERS,
})
export class ServicesModule {}
