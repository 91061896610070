import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'renovars-file-menu-dialog',
  template: ` <p-menu [model]="items"></p-menu> `,
  styles: [],
})
export class FileMenuDialogComponent implements OnInit {
  items: MenuItem[];
  ngOnInit(): void {
    this.items = [
      {
        label: 'scarica',
        icon: 'pi pi-fw pi-download',
        command: (e) => {
          console.log(e);
        },
      },
      {
        label: 'rinomina',
        icon: 'pi pi-fw pi-edit',
        command: (e) => {
          console.log(e);
        },
      },
      {
        label: 'elimina',
        icon: 'pi pi-fw pi-times',
        command: (e) => {
          console.log(e);
        },
      },
    ];
  }
}
