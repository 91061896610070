import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppConfigModule } from './app-config';
import { AuthGuard } from './guards/auth.guard';
import { SetHeaderInterceptor } from './interceptor/set-header.interceptor';
import { AppResolver } from './resolver/app.resolver';

const DECLARATIONS = [];

@NgModule({
  declarations: DECLARATIONS,
  imports: [CommonModule, AppConfigModule],
  providers: [AppResolver, SetHeaderInterceptor, AuthGuard],
  exports: [...DECLARATIONS],
})
export class CoreModule {}
