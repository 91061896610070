import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss'],
})
export class RoadmapComponent {

  //TODO: load links from db, cms, env

  externalResources = [
    {
      label: 'Backlog',
      url: this.trustUrl('https://sharing.clickup.com/24345238/l/h/q6ymp-13910/afd48628279d151'),
    },
    {
      label: 'Sprint precedente',
      url: this.trustUrl('https://sharing.clickup.com/24345238/l/h/q6ymp-13930/927d6c5c175680c'),
    },
    {
      label: 'Sprint attuale',
      url: this.trustUrl('https://sharing.clickup.com/24345238/l/h/q6ymp-13950/8fe73cf15af52dc'),
    },
    {
      label: 'Sprint successivo',
      url: this.trustUrl('https://sharing.clickup.com/24345238/l/h/q6ymp-13970/8190a3e1d506b25'),
    },

  ];

  constructor(private sanitizer: DomSanitizer) {}

  trustUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
