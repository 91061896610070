<fullstack-header></fullstack-header>

<ng-template header-body>
  <h1 class="tx-title-1 m-2">Roadmap</h1>
</ng-template>

<mat-tab-group animationDuration="40ms">
  <mat-tab *ngFor="let clickUpResource of externalResources" [label]="clickUpResource.label">
    <iframe
      class="e2e-iframe-trusted-src clickup-iframe"
      width="100%"
      height="100%"
      [src]="clickUpResource.url"
    ></iframe>
  </mat-tab>
</mat-tab-group>
