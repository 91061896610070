import { Component } from '@angular/core';

@Component({
  selector: 'welcome',
  template: `<div class="content text-center pt-5 mx-0">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <h1 class="tx-title cl-fourth">Benvenuto</h1>
      </div>
    </div>
  </div>`,
})
export class WelcomeComponent {}
